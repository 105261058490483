<template>
    <div v-if="details.social_media.length!==0" class="bg-primary pos-fixed fs--1">
        <div class="container">
            <div class="fl-y fl-a-e">
                <div class="social-media bg-primary pt-2 fl-x-cc">
                    <a v-for="(i, index) in details.social_media" :key="index" :href="i.website_link">
                        <span v-if="i.site_map_type!=='Call us'" class="social-icon border-r-1 p-1">
                            <img v-if="i.site_map_type==='Facebook'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/fb.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Instagram'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/insta.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='LinkedIn'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/linkedin.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Pinterest'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/pinterest.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='QQ'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/qq.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Quora'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/quora.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Reddit'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/reddit.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Sina Weibo'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/Sina_Weibo.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Skype'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/skype.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='SnapChat'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/Snapchat-.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Microsoft Teams'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/teams.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Telegram'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/telegram.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Twitter'" height="15" width="14"
                                 src="../../assets/web/social-media-icons/twitter.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Youtube'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/utube.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='WeChat'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/Wechat.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='WhatsApp'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/whatsapp.png" alt=""
                                 class="img-fluid"/>
                        </span>
                    </a>
                </div>
                <div class="fl-x fl-a-c fl-j-c">
                    <div class="text-center-res p-1 px-2 b-r-1">
                        Call Us: <a :href="'tel:'+ getCallUs"
                                    class="text-decoration-none font-inter-light mb-0 fs--1">
                        {{ getCallUs }}</a>
                    </div>
                    <div class="text-center-res p-2 px-2">
                        <a href="" class="text-decoration-none">Know Legal Information <span class="ml-2"><i
                            class="fa fa-chevron-right fs--1 font-weight-normal"></i></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="bg-primary pos-fixed fs--1">
        <div class="container">
            <div class="social-media bg-primary pt-2">
            </div>
            <div class="fl-x fl-a-c fl-j-c">
                <div class="text-center-res p-1 px-2 b-r-1">
                    Call Us: <a :href="'tel:'+ getCallUs"
                                class="text-decoration-none font-inter-light mb-0 fs--1">
                    {{ getCallUs }}</a>
                </div>
                <div class="text-center-res p-2 px-2">
                    <a href="" class="text-decoration-none">Know Legal Information <span class="ml-2"><i
                        class="fa fa-chevron-right fs--1 font-weight-normal"></i></span></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopHeaderMobile',
    props: {
        details: { type: Object, default: null },
        currentRoute: {
            type: String,
            default: ''
        }
    },
    computed: {
        getCallUs () {
            const item = this.details.social_media.filter(obj => obj.site_map_type === 'Call us');
            if (item.length !== 0) {
                return item[0].phone_number;
            } else {
                return '';
            }
        },
        getWGroup () {
            const item = this.details.social_media.filter(obj => obj.site_map_type === 'Whatsapp group');
            if (item.length !== 0) {
                return item[0].website_link;
            } else {
                return '';
            }
        }
    },
    data () {
        return {
            isAboutPage: this.$router.currentRoute.name,
            showLegalInfo: false
        };
    },
    watch: {
        isAboutPage (val) {
            if (val === 'About') {
                this.showLegalInfo = true;
            } else {
                this.showLegalInfo = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
    .pos-fixed {
        position: fixed;
        width: 100%;
        z-index: 999;
        top: 0;
    }
</style>
