<template>
    <div v-if="details.social_media.length!==0" class="bg-primary pt-1 pb-1 border-abs pos-fixed">
        <div class="container">
            <div class="fl-x fl-j-sb">
                <div class="fl-x-res">
                    <div v-if="getCallUs!==''" class="fl-x-res fl-a-c mr-3">
                        <div class="border-r-1 border-rounded text-center fl-x-cc mb-3 mb-lg-0 mr-2">
                            <!--                            <span class="header-icon">-->
                            <a :href="'tel:'+ getCallUs"
                               class="text-decoration-none header-icon mt-2">
                                <img src="../../assets/web/home/icons/phone.png" alt=""
                                     class="img-fluid">
                            </a>
                        </div>
                        <div class="text-center-res">
                            <p class="font-inter-light mb-0 fs--1">
                                Call us:<a :href="'tel:'+ getCallUs"
                                           class="text-decoration-none">{{ getCallUs }}</a>
                            </p>
                        </div>
                    </div>
                    <div class="fl-x-res fl-a-c" v-if="getWGroup!==''">
                        <div class="border-r-1 border-rounded text-center fl-x-cc mb-3 mb-lg-0 mr-2">
                            <span class="header-icon mt-2"><img src="../../assets/web/home/icons/whatsapp.png" alt=""
                                                                class="img-fluid"></span>
                        </div>
                        <div class="text-center-res">
                            <a class="font-inter-light mb-0 fs--1 text-decoration-none" :href="getWGroup">Join our
                                Whatsapp group</a>
                        </div>
                    </div>
                </div>
                <div class="social-media bg-primary fl-x-cc ">
                    <a v-for="(i, index) in details.social_media" target="_blank" :key="index" :href="i.website_link">
                        <span v-if="i.site_map_type!=='Call us'" class="social-icon border-r-1 p-1">
                            <img v-if="i.site_map_type==='Facebook'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/fb.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Instagram'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/insta.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='LinkedIn'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/linkedin.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Pinterest'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/pinterest.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='QQ'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/qq.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Quora'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/quora.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Reddit'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/reddit.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Sina Weibo'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/Sina_Weibo.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Skype'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/skype.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='SnapChat'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/Snapchat-.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Microsoft Teams'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/teams.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Telegram'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/telegram.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Twitter'" height="15" width="14"
                                 src="../../assets/web/social-media-icons/twitter.png" alt=""
                                 class="img-fluid mt-n4"/>
                            <img v-if="i.site_map_type==='Youtube'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/utube.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='WeChat'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/Wechat.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='WhatsApp'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/whatsapp.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Whatsapp group'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/whatsapp-group.png" alt=""
                                 class="img-fluid"/>
                            <img v-if="i.site_map_type==='Google Plus'" height="15" width="22"
                                 src="../../assets/web/social-media-icons/google-plus.png" alt=""
                                 class="img-fluid"/>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="p-5 bg-primary pt-2 pb-3 border-abs pos-fixed">
        <div class="container">
            <div class="fl-x fl-j-sb">
                <div class="social-media bg-primary ">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TopHeader',
    props: {
        details: { type: Object, default: null },
        currentRoute: {
            type: String,
            default: ''
        }
    },
    computed: {
        getCallUs () {
            const item = this.details.social_media.filter(obj => obj.site_map_type === 'Call us');
            if (item.length !== 0) {
                return item[0].phone_number;
            } else {
                return '';
            }
        },
        getWGroup () {
            const item = this.details.social_media.filter(obj => obj.site_map_type === 'Whatsapp group');
            if (item.length !== 0) {
                return item[0].website_link;
            } else {
                return '';
            }
        }
    },
    data () {
        return {
            isAboutPage: this.$router.currentRoute.name,
            showLegalInfo: false
        };
    },
    watch: {
        isAboutPage (val) {
            if (val === 'About') {
                this.showLegalInfo = true;
            } else {
                this.showLegalInfo = false;
            }
        }
    }
};
</script>
<style scoped lang="scss">
    .d-inline {
        display: inline;
    }

    .pos-fixed {
        position: fixed;
        width: 100%;
        z-index: 999;
    }

    .border-rounded {
        border-radius: 50%;
    }

    .w-7p {
        //width: 7%;
        width: 16px;
    }

    .icon-item {
        position: relative;
        width: 28px;
        height: 28px;
        background-color: var(--color-secondary);
        border-radius: 50%;
        margin: 0;

        i {
            position: fixed;
            z-index: 1001 !important;
        }

        //::before {
        //    content: "";
        //    width: 16px;
        //    height: 16px;
        //    position: absolute;
        //    background-color: var(--color-secondary);
        //    border-radius: 50%;
        //    z-index: 1000 !important;
        //}
    }

    //.social-icon {
    //    padding: 1rem;
    //    transition: all 300ms ease-in-out;
    //    border: 2px solid;
    //    display: inline-flex;
    //    align-items: center;
    //    justify-content: center;
    //    height: 40px;
    //    width: 40px;
    //    color: var(--color-white);
    //    background: #c9c9c926;
    //    margin-left: 10px;
    //    text-decoration: none;
    //    cursor: pointer;
    //
    //    &:hover {
    //        opacity: 0.8;
    //        cursor: pointer;
    //
    //        background-color: var(--primary-900);
    //        color: black;
    //    }
    //}
    .border-abs {
        //border-bottom: var(--border-1) solid var(--border-color) !important;
        ::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            bottom: 0;
            background-color: var(--border-color);
        }
    }

    .header-icon {
        width: 1.8rem;
        height: auto;
    }
</style>
